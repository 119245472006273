body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  color:rgb(150, 150, 150);
  /* background-color: black !important; */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  /* background-color: black; */
}



#red{
  color:#fda204;
}

#Content{
  margin-top: 4rem;
}

.Site-header{
  /* display: block; */
  text-align:right;
  color: #0177bd;
  font-weight: 600;
  
}

.Site-logo{
  display:inline;
  float:left;
  /* width:10rem; */
  /* margin-top: -1.8rem; */
  margin-top: 5px !important;
  width: 100px !important;
}

.Header-header{
  display: block;
  margin-top: 0rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 5rem;
  padding-right: 5rem;
  /* border-bottom: #dfdfdf1f solid 1px; */
  position:fixed;
  width: 100%;
  height: 4rem;
  background-color: white !important;
  /* box-shadow: rgb(0 0 0 / 7%) 0px 3px 4px; */
}

.Header-title{
  display:inline;
  margin-left: 1rem;
  padding-top: 1rem;
  margin-top: 1rem;
}

.Header-menu{
  display: block;
  /* float: right; */
  padding-top: 1em;
  /* padding: 0px; */
  padding-right: 7rem;
  margin: 0px;
  text-align: center;
  display:inline-block;
}

.Header-menu li{
  float: right;
  list-style-type: none;
  /* padding: 0px;
  height: 24px; */
  /* margin-top: 4px;
  margin-bottom: 4px; */
  padding: 1rem;
  display: inline;
  
}

.Header-menu li a{
 text-decoration: none;
 color: #0177bd;
 border:'1px solid white'; 
}

.Header-menu li a:hover{
  text-decoration: none;
  color: #fda204;
 }

.Footer{
  /* position: fixed;
  bottom: 0; */
  margin-top: 2px;
  display: flex;
  width: 100%;
  /* height: 20rem; */
  /* background-color: #0177bd !important; */
  color:#dfdfdf;
}

.BottomFooter{
  text-align:center;
  background-color: #0177bd !important;
  color:white !important;
  /* height: 5rem; */
  padding:1rem;
}


.Footer-section{
  display: inline;  
  width: 33%;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
  border-bottom-color:#dfdfdf52;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  /* margin-bottom: 3rem; */
}

.Footer-section-wd{
  display: block;
  width: 100%;
  border-top-color:whitesmoke;
  border-top-style: solid;
  border-top-width: 1px;
}

.App-content{
  margin-left: 10rem;
  margin-right: 10rem;
  text-align: left;
}

.about-right{
  width: 30%;
  height: 25rem;
  background-color: #5a270238;
  z-index: -1;
  /* border-radius: tl te br bl; */
  border-radius: 20% 20% 100% 20%;
  /* border-radius: 20% 40% 70px 100%; */
  padding-top: 5rem;
  padding-left: 5rem;
  margin-bottom: 2rem;
  display: inline;

  position: relative;
  -webkit-animation-name: exampleAbout; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 8s; /* Safari 4.0 - 8.0 */
  animation-name: exampleAbout;
  animation-duration: 8s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes exampleAbout {
  0%   {background-color:#dfdfdf17; left:200px; top:0px;}
  25%  {background-color:#dfdfdf17; left:0px; top:0px;}
}

/* Standard syntax */
@keyframes exampleAbout {
  0%   {background-color:#dfdfdf17; left:200px; top:0px;}
  25%  {background-color:#dfdfdf17; left:0px; top:0px;}
}

.about-right-down{
  z-index: -1;
  width: 30%;
  height: 25rem;
  background-color: #5a270238;
  border-radius: 20% 20% 20% 100%;
  padding-top: 5rem;
  padding-left: 5rem;
  margin-bottom: 2rem;
  display: inline;

  position: relative;
  -webkit-animation-name: exampleAboutDown; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 8s; /* Safari 4.0 - 8.0 */
  animation-name: exampleAboutDown;
  animation-duration: 8s;

}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes exampleAboutDown {
  0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
  25%  {background-color:#dfdfdf17; left:0px; top:0px;}
}

/* Standard syntax */
@keyframes exampleAboutDown {
  0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
  25%  {background-color:#dfdfdf17; left:0px; top:0px;}
}

.about-left{
  width: 50%;
  display: inline;
  z-index: -1;
}

.about-right-span{
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 600;
  color: #078fc3;
}

.item{
  position: absolute;  
  width: 20px;
  height: 20px;
  background: #078fc3;
  /* top: 1.7rem;
  left: 1rem; */
  border-radius: 30% 0px 30% 0%;
  transform: rotate(45deg);
  z-index: -1;
  margin-right: 1px;

}