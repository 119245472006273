.App {
  text-align: center;
  padding-top: 6rem;
}

.Home-image {
  width: 100%;
  background-image: url("./bg444.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 50rem;
 
}


.Home-image h3 {
  font-size: 4rem;
  color:white;
  width: 40%;
  text-align: start;
  padding-top: 10rem;
  margin-left: 5rem;
  
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes exampleh3 {
  0%   {color:white;}
  50%  {color:#0494bf;}
}

/* Standard syntax */
@keyframes exampleh3 {
  0%   {color:white;}
  50%  {color:#0494bf;}
}

#diff {
  color: #fd5505;
  -webkit-animation-name: exampleh3; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 8s; /* Safari 4.0 - 8.0 */
  animation-name: exampleh3;
  animation-duration: 8s;
}

.Home-section-wd{
  display: inline-flex;
  margin-left: 20rem;
  margin-right: 20rem;
}

.Home-section{
  width: 50%;
  /* border: solid 1px #dfdfdf; */
  /* background-color: #dfdfdf17 ; */
  margin: 2rem;
  display: inline;
  padding-left: 2rem;
  padding-right: 2rem;
  /* border-radius: 3px; */

  font-size: 1rem;
  color: rgb(150, 150, 150);

    /* top: 1rem; */
    background: rgb(255, 255, 255);
    /* background-color: white; */
    /* padding: 35px 20px 20px; */
    z-index: 9;
    box-shadow: rgb(0 0 0 / 7%) 0px 4px 8px;
    border-radius: 5px;

    
      
}

.Home-section h3{
    color: rgb(24, 43, 73);
    text-transform: uppercase;
    font-weight: 600;
}

#object3 {
  background-color: #dfdfdf17;
  background: linear-gradient(to bottom right, #fda204 0%, #fda204 5%, #fff 5%, #fff 100%);
  position: relative;
  -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 8s; /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 8s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
  0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
  25%  {background-color:#dfdfdf17; left:0px; top:0px;}
}

/* Standard syntax */
@keyframes example {
  /* 0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
  10%   {background-color:#dfdfdf17; top:20px;}
  25%  {background-color:#dfdfdf17;  top:-20px;}
  100%  {background-color:#dfdfdf17; left:0px; top:0px;} */
  0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
  25%  {background-color:#dfdfdf17; left:0px; top:0px;}
}

#object4 {
  background-color: #dfdfdf17;
  background: linear-gradient(to bottom left, #0177bd 0%, #0177bd 5%, #fff 5%, #fff 100%);
  

  position: relative;
  -webkit-animation-name: example4; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 8s; /* Safari 4.0 - 8.0 */
  animation-name: example4;
  animation-duration: 8s;
}



.item-right{
  margin-left: 71px;

}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes example4 {
  0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
  25%  {background-color:#dfdfdf17; left:0px; top:0px;}
}

/* Standard syntax */
@keyframes example4 {
  /* 0%   {background-color:#dfdfdf17; left:-200px; top:0px;}
  10%   {background-color:#dfdfdf17; top:20px;}
  25%  {background-color:#dfdfdf17;  top:-20px;}
  100%  {background-color:#dfdfdf17; left:0px; top:0px;} */
  0%   {background-color:#dfdfdf17; left:200px; top:0px;}
  25%  {background-color:#dfdfdf17; left:0px; top:0px;}
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-home {
  /* margin-top: 1rem; */

}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #ff9900d4;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
